import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'
import GalleryFilter from 'components/GalleryFilter'
import GalleryGrid from 'components/GalleryGrid'
import Layout from 'components/Layout'
import ModuleHeader from 'components/ModuleHeader'
import Pagination from 'components/Pagination'
import RedirectUnauthed from 'components/RedirectUnauthed'
import useFileUpload from 'hooks/useFileUpload'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import tw from 'twin.macro'

export const GALLERY_QUERY = gql`
  query galleryQuery {
    queryKaraokePerformance(filter: { isUploaded: true }) {
      id
      song {
        title
        artist
        videoUrl
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        avatarImage
        name
        avatarImageIsUploaded
      }
    }
  }
`

export default function FileListPage() {
  const [category, setCategory] = useState('all')
  const [top10, setTop10] = useState()
  const [selectedSong, setSelectedSong] = useState(null)
  const [filteredData, setFilteredData] = useState(null)
  const [first] = useState(8)
  const [offset, setOffset] = useState(0)
  let perPage = 8
  const [pageCount, setPageCount] = useState(null)
  const [queryData, setQueryData] = useState(null)

  const responsive = {
    supersuperLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1870 },
      items: 6,
    },
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1270 },
      items: 4,
    },
    horizontalTablet: {
      breakpoint: { max: 1270, min: 968 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 968, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const { loading, error, data } = useQuery(GALLERY_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => sortTop10(data),
  })

  const sortTop10 = (data) => {
    if (data.queryKaraokePerformance.length > 0) {
      let result = {}
      let sortableData = data.queryKaraokePerformance
      let sortedData = sortableData.slice().sort((a, b) => b.likedByAggregate.count - a.likedByAggregate.count)
      let arr = []
      sortedData.slice(0, 10).map((item, i) => {
        arr.push(item)
      })
      result.queryKaraokePerformance = arr
      setTop10(result)
    }
  }

  const handlePageClick = useCallback(
    (e) => {
      const selectedPage = e.selected
      const tempOffset = selectedPage * perPage

      setOffset(tempOffset)
      // console.log(queryData)
    },
    [perPage]
  )

  const changeSongHandler = useCallback((e) => {
    setSelectedSong(e)
    setOffset(0)
  }, [])

  const handleTabChange = useCallback((e) => {
    setCategory(e)
    setOffset(0)
  }, [])

  const handleFilterData = useCallback(
    (data) => {
      if (data && data.queryKaraokePerformance.length > 0) {
        setQueryData(data)
        const slice = data.queryKaraokePerformance.slice(offset, offset + perPage)

        // const postData = slice.map((data, i) => <GalleryItem onClick={openLightboxOnSlide} data={data} i={i} key={i} />)
        setPageCount(Math.ceil(data.queryKaraokePerformance.length / perPage))
        setFilteredData({ queryKaraokePerformance: slice })
      } else {
        setFilteredData({ queryKaraokePerformance: [] })
        setPageCount(0)
      }
    },
    [offset, perPage]
  )

  useEffect(() => {
    handleFilterData(queryData)
  }, [offset])

  return (
    <RedirectUnauthed to="/">
      <Layout>
        <Page>
          <ModuleHeader
            title={'Hall of Fame'}
            subtitle={'Karaoke and Dance'}
            text={'Be inspired and loop up your own song for the hall of fame.'}
            bgColor={'#4FA0D3'}
            color={'#000'}
            imgUrl={'https://picsum.photos/id/1/1920/1080'}
            lottie={'LottieTurnTable'}
          />
          <div tw="bg-zalando-turquese-300 w-full flex justify-center h-auto min-h-full py-16 px-8 flex flex-col ">
            <div tw="max-w-7xl m-auto w-full">
              <SectionTitle>Top 10</SectionTitle>
            </div>
            <div tw="flex justify-center">
              <GalleryGrid imageList={top10} numberOfImages={10} gridOptions={responsive} />
            </div>
          </div>
          <div tw="w-full max-w-7xl px-12 xl:px-0">
            <div tw={'flex w-full justify-between items-center  max-w-7xl mt-32'}>
              <GalleryFilter
                onChange={changeSongHandler}
                onTabChange={handleTabChange}
                onData={handleFilterData}
                first={first}
                offset={offset}
                // selectedSong={selectedSong}
              />
            </div>
            <GalleryGrid
              pagination
              filtered
              imageList={filteredData}
              selectedSong={selectedSong}
              selectedCat={category}
            />
            <>
              {/* <Pagination onNext={handleNext} onPrev={handlePrev} first={first} offset={offset} /> */}
              <ClassNames>
                {({ css }) => (
                  <ReactPaginate
                    previousLabel={'PREV'}
                    nextLabel={'NEXT'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={css({ width: '100%', display: 'flex', justifyContent: 'center' })}
                    subContainerClassName={'pages pagination'}
                    activeClassName={css({ fontWeight: 'bold', color: 'white', backgroundColor: '#000' })}
                    activeLinkClassName={css(
                      tw`
                        w-full
                        h-full
                        text-white
                        hover:text-white
                        focus:text-white
                      `
                    )}
                    previousClassName={css(
                      tw`
                        border
                        border-black
                        p-4
                        px-8
                        border-r-0
                      `
                    )}
                    nextClassName={css(
                      tw`
                        border
                        border-black
                        p-4
                        px-8
                      `
                    )}
                    pageClassName={css(
                      tw`
                        border
                        border-black
                        border-r-0
                      `
                    )}
                    pageLinkClassName={css(
                      tw`
                        w-full
                        h-full
                        p-4
                        px-6
                        flex
                        items-center
                        `
                    )}
                  />
                )}
              </ClassNames>
            </>
          </div>
        </Page>
      </Layout>
    </RedirectUnauthed>
  )
}

FileListPage.propTypes = {
  data: PropTypes.array,
}

const Page = styled.div(tw`
  flex
  justify-center
  items-center
  flex-col
  w-full
  `)

const SectionTitle = styled.h1(tw`
  text-base
  font-bold
  tracking-widest

`)
