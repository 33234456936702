import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import useOutsideClick from 'hooks/useOutsideClick'
import { MediaElementSyncer } from 'media-element-syncer'
import { GALLERY_QUERY } from 'pages/gallery'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import tw from 'twin.macro'

import AvatarIcon from './AvatarIcon'
import HeartIconWrapper from './HeartIconWrapper'

export const LIKE_ITEM = gql`
  mutation likeKaraokePerformance($id: ID!) {
    likeKaraokePerformance(id: $id) {
      id
    }
  }
`

export const DISLIKE_ITEM = gql`
  mutation dislikeKaraokePerformance($id: ID!) {
    dislikeKaraokePerformance(id: $id) {
      id
    }
  }
`
export default function LightboxItem({ videoSrcMp4, videoSrcWebM, audioSrc, isOpen, duration, data, closeBox }) {
  const video1 = useRef()
  const audio = useRef()
  const video = useRef()
  const timing = useRef()
  const mediaSync = useRef()
  const to = useRef()

  let videoPlayers = []
  let playerNames = []
  const [setup, setSetup] = useState(false)
  const [liked, setLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(data.likedByAggregate?.count)

  const username = useSelector((state) => state.username)

  const [likeItemMutation] = useMutation(LIKE_ITEM, {
    refetchQueries: [{ query: GALLERY_QUERY }],
    fetchPolicy: 'no-cache',
  })
  const [dislikeItemMutation] = useMutation(DISLIKE_ITEM, {
    refetchQueries: [{ query: GALLERY_QUERY }],
    fetchPolicy: 'no-cache',
  })

  if (audio.current && video1.current) {
    let syncer = new MediaElementSyncer(video1.current)
    syncer.addChild(audio.current, { offset: data.offset > 0 ? -data.offset : data.offset })
    console.log('added Syncer')
  }

  const likeItem = useCallback(() => {
    //like or dislike video based on current like state
    if (!liked) {
      likeItemMutation({ variables: { id: data.id } }).then(() => {
        setLiked(!liked)
        setLikeCount((count) => count + 1)
      })
    } else {
      dislikeItemMutation({ variables: { id: data.id } }).then(() => {
        setLiked(!liked)
        setLikeCount((count) => count - 1)
      })
    }
  }, [data, dislikeItemMutation, likeItemMutation, liked])

  const outside = useOutsideClick(() => {
    closeBox()
  })

  if (video1.current) {
    videoPlayers = [video1.current]

    videoPlayers.forEach((i) => {
      playerNames.push(i.attributes?.name)
      i.controls = false
      i.playsinline = true
      i.autoplay = false
    })
  }

  // const startVideos = useCallback(() => {
  //   if (to.current) {
  //     var v = to.current.query()

  //     if (v.position === 100 && v.velocity === 0) {
  //       to.current.update({ position: 0.0, velocity: 1.0 })
  //     } else to.current.update({ velocity: 1.0 })
  //   }
  // }, [])

  // const stopVideos = useCallback(() => {
  //   if (to.current) {
  //     to.current.update({ velocity: 0.0, position: 0.0 })
  //   }
  // })

  // Setup Timing and Media Sync
  const setupTimer = useCallback(() => {
    // to.current = new timing.current.TimingObject({ range: [0, time] })
    // if (timing.current && mediaSync.current && video1.current && audio.current && to.current) {
    //   mediaSync.current.mediaSync(video1.current, to.current)
    //   mediaSync.current.mediaSync(audio.current, to.current)
    setSetup(true)
    // }
  }, [])

  // //import modules
  // const loadTiming = async () => {
  //   return {
  //     Timing: await import('lib/timingsrc'),
  //     MediaSync: (await import('lib/mediasync')).default,
  //   }
  // }

  useEffect(() => {
    if (isOpen && video.current) {
      console.log(video.current)
      // startVideos()
      video.current.load()
      video.current.play()
    } else {
      video.current.pause()
      // stopVideos()
    }
  }, [isOpen])

  useEffect(() => {
    // check if user liked video
    if (data) {
      if (data.likedBy.length > 0) {
        let included = data.likedBy.map((i) => {
          if (i.name === username) return true
        })
        if (included) {
          setLiked(true)
        }
      }
    }
  }, [data, username])

  return (
    <div ref={outside}>
      <VideoWrapper>
        <Details>
          <ImageDetailsUserWrapper>
            {data.user && (
              <ImageDetailsUser>
                <UserImageWrapper>
                  <AvatarIcon small currentUser={data.user} />
                </UserImageWrapper>
                <UserName>{data.user.name}</UserName>
              </ImageDetailsUser>
            )}
            <SongName>
              {data.song.artist} – {data.song.title}
            </SongName>
            <ImageLikeCountAndButton>
              <LikeCount>{likeCount} Likes</LikeCount>
              <HeartIconWrapper liked={liked} onClick={() => likeItem()} />
            </ImageLikeCountAndButton>
          </ImageDetailsUserWrapper>
        </Details>

        {/* <video ref={video1} tw="w-64"> */}
        <video ref={video}>
          <source src={videoSrcWebM} type="video/webm"></source>
          <source src={videoSrcMp4} type="video/mp4"></source>
        </video>

        {/* <video src={audioSrc} ref={audio} hidden /> */}
      </VideoWrapper>
    </div>
  )
}

const VideoWrapper = styled.div(tw`
  w-full
  h-full
  relative
  flex
  items-end
`)

const Details = styled.div(tw`
  flex
  flex-col
  justify-end

  h-16
  w-full
  bg-gradient-to-t
  from-black-op-40
  absolute
`)

const ImageDetailsUserWrapper = styled.div(tw`
  flex
  justify-between
  items-center
`)
const ImageDetailsUser = styled.div(tw`
  flex
  items-center
  p-2
`)

const UserImageWrapper = styled.div(tw`
  w-6
  h-6
  rounded-full
  overflow-hidden
`)

const UserImage = styled.img(tw`
  w-full
`)

const UserName = styled.p(tw`

  text-white
  text-base
  ml-2
`)

const ImageLikeCountAndButton = styled.div(tw`
  flex
  items-center
  p-2
`)

const LikeCount = styled.p(tw`
  pr-2
  text-white
  text-base
  font-bold
`)

const SongName = styled.p(tw`
  font-bold
  text-base
  text-white
`)

LightboxItem.propTypes = {
  videoSrc: PropTypes.string,
  audioSrc: PropTypes.string,
  isOpen: PropTypes.bool,
  duration: PropTypes.number,
  data: PropTypes.object,
}
