import axios from 'axios'
import { useCallback, useState } from 'react'

export default function useFileUpload() {
  const [selectedFile, setSelectedFile] = useState([])
  const [imageURL, setImageURL] = useState(null)
  const [fileChosen, setFileChosen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const sendForm = useCallback(() => {
    return new Promise((resolve, reject) => {
      //   let url =
      // 'https://zalando-test.s3.eu-central-1.amazonaws.com/test_file_1.png?Content-Type=image/png&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ4CF5ZEHDEFDJPYQ/20201026/eu-central-1/s3/aws4_request&X-Amz-Date=20201026T212648Z&X-Amz-Expires=3600&X-Amz-Signature=e86c2caa0e9e7df4b29b8193fd6bcb083dd26811271ac7492ff137d18776ae3e&X-Amz-SignedHeaders=host'
      // 'https://zalando-test.s3.eu-central-1.amazonaws.com/test_file_1.png?Content-Type=image/png&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ4CF5ZEHDEFDJPYQ/20201027/eu-central-1/s3/aws4_request&X-Amz-Date=20201027T104720Z&X-Amz-Expires=3600&X-Amz-Signature=cd44849dd98b82003ff99433c74c24e0488ab9f650c91cd44fb42bf381b9e467&X-Amz-SignedHeaders=host;x-amz-tagging&x-amz-tagging=cat=photos'
      // 'https://1234bucketfoo.s3.eu-central-1.amazonaws.com/test_file_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201102%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201102T104747Z&X-Amz-Expires=604800&X-Amz-Signature=fd8fbbcdaf8789b2a7f060c98249c4583b4cf65f9202a4e19c60bafe80b00b4e&X-Amz-SignedHeaders=host'
      // 'https://1234bucketfoo.s3.amazonaws.com/test_file_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201201%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201201T000000Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d24dccf5f3033b53605fe2e75359fcfb08259df6de916a4b87408364c5c1fb8a'
      // 'https://1234bucketfoo.s3.amazonaws.com/3809b17d54851380ca9c723c99c200b6?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201202%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201202T130455Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ca993849f8083fbe98f5679ab3fe9aaffb1cca1676491509220346d8dec6c4bf'

      // get the Presigend URL before sending the data
      getPresignedURL().then((url) => {
        setError(false)
        setSuccess(false)
        var file = selectedFile[0]
        console.log(file)
        const config = {}
        if (file !== undefined && url) {
          setLoading(true)
          axios
            .put(url.put, file, config)
            .then(async (res) => {
              console.log(res)
              setSelectedFile([])
              setFileChosen(false)
              setImageURL(url.get)
              setLoading(false)

              resolve('https://ik.imagekit.io/pfqtgknzf8w/' + url.filename)
              setSuccess(true)
            })
            .catch((err) => {
              setSelectedFile([])
              setImageURL(null)
              setFileChosen(false)
              console.log(err)
              setLoading(false)
              reject(err)
              setError(true)
            })
        }
      })
    })

    //   e.preventDefault()
  }, [selectedFile, setSelectedFile, getPresignedURL, setImageURL, setFileChosen, setLoading, setSuccess, setError])

  const sendDroppedForm = useCallback(
    (e) => {
      return new Promise((resolve, reject) => {
        //   let url =
        // 'https://zalando-test.s3.eu-central-1.amazonaws.com/test_file_1.png?Content-Type=image/png&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ4CF5ZEHDEFDJPYQ/20201026/eu-central-1/s3/aws4_request&X-Amz-Date=20201026T212648Z&X-Amz-Expires=3600&X-Amz-Signature=e86c2caa0e9e7df4b29b8193fd6bcb083dd26811271ac7492ff137d18776ae3e&X-Amz-SignedHeaders=host'
        // 'https://zalando-test.s3.eu-central-1.amazonaws.com/test_file_1.png?Content-Type=image/png&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ4CF5ZEHDEFDJPYQ/20201027/eu-central-1/s3/aws4_request&X-Amz-Date=20201027T104720Z&X-Amz-Expires=3600&X-Amz-Signature=cd44849dd98b82003ff99433c74c24e0488ab9f650c91cd44fb42bf381b9e467&X-Amz-SignedHeaders=host;x-amz-tagging&x-amz-tagging=cat=photos'
        // 'https://1234bucketfoo.s3.eu-central-1.amazonaws.com/test_file_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201102%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201102T104747Z&X-Amz-Expires=604800&X-Amz-Signature=fd8fbbcdaf8789b2a7f060c98249c4583b4cf65f9202a4e19c60bafe80b00b4e&X-Amz-SignedHeaders=host'
        // 'https://1234bucketfoo.s3.amazonaws.com/test_file_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201201%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201201T000000Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d24dccf5f3033b53605fe2e75359fcfb08259df6de916a4b87408364c5c1fb8a'
        // 'https://1234bucketfoo.s3.amazonaws.com/3809b17d54851380ca9c723c99c200b6?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4EF5G7APVZGEPSPR%2F20201202%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20201202T130455Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ca993849f8083fbe98f5679ab3fe9aaffb1cca1676491509220346d8dec6c4bf'

        // get the Presigend URL before sending the data
        getPresignedURL().then((url) => {
          setError(false)
          setSuccess(false)
          var file = e[0]

          // var file = selectedFile[0]
          console.log(file)
          const config = {}
          if (file !== undefined && url) {
            setLoading(true)
            axios
              .put(url.put, file, config)
              .then(async (res) => {
                console.log(res)
                setSelectedFile([])
                setFileChosen(false)
                setImageURL(url.get)
                setLoading(false)

                resolve('https://ik.imagekit.io/pfqtgknzf8w/' + url.filename)
                setSuccess(true)
              })
              .catch((err) => {
                setSelectedFile([])
                setImageURL(null)
                setFileChosen(false)
                console.log(err)
                setLoading(false)
                reject(err)
                setError(true)
              })
          }
        })
      })

      //   e.preventDefault()
    },
    [setSelectedFile, getPresignedURL, setImageURL, setFileChosen, setLoading, setSuccess, setError]
  )
  // request Presigned Url from Signer
  const getPresignedURL = useCallback(() => {
    return new Promise((resolve, reject) => {
      axios
        .get('https://dev01.zalando.meso.design/')
        .then((res) => {
          console.log(res.data)
          resolve({
            put: res.data['Presigned url for PUT (.png)'],
            get: res.data['Presigned url for GET (.png)'],
            filename: res.data['.png file name'],
          })
        })
        .catch((err) => reject(err))
    })
  }, [])

  const onChangeHandler = useCallback(
    (e) => {
      if (e.target) {
        setSelectedFile(e.target.files)
        setFileChosen(true)
      }
    },
    [setSelectedFile, setFileChosen]
  )

  const onDropHandler = useCallback(
    (e) => {
      return new Promise((resolve, reject) => {
        if (e.length) {
          console.log(e)
          sendDroppedForm(e)
            .then((res) => {
              setFileChosen(true)
              resolve(res)
            })
            .catch((err) => {
              setFileChosen(false)
              reject(err)
            })
        }
      })
    },
    [sendDroppedForm, setFileChosen]
  )
  return {
    selectedFile,
    imageURL,
    setSelectedFile,
    sendForm,
    onChangeHandler,
    onDropHandler,
    fileChosen,
    loading,
    success,
    error,
  }
}
