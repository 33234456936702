import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import ReactPaginate from 'react-paginate'
import tw from 'twin.macro'

export default function Pagination({ onPrev, onNext }) {
  return <></>
}

const PaginationWrapper = styled.div(tw`
  w-full
  h-16
  flex
  justify-center
`)

const PaginationButton = styled.div(
  tw`
  w-24
  h-16
  border-2
  border-black
  flex
  justify-center 
  items-center
  cursor-pointer
  hover:text-white
  hover:bg-black
`,
  css`
    :first-of-type {
      border-right: 0;
    }
  `
)
