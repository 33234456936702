import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { HeartIcon } from '@heroicons/react/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'
import useAuth from 'hooks/useAuth'
import { GALLERY_QUERY } from 'pages/gallery'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import tw from 'twin.macro'

import AvatarIcon from './AvatarIcon'

export const LIKE_ITEM = gql`
  mutation likeKaraokePerformance($id: ID!) {
    likeKaraokePerformance(id: $id) {
      id
    }
  }
`

export const DISLIKE_ITEM = gql`
  mutation dislikeKaraokePerformance($id: ID!) {
    dislikeKaraokePerformance(id: $id) {
      id
    }
  }
`
export default function GalleryItem({ onClick, data }) {
  const { currentUser } = useAuth()
  const [liked, setLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(data.likedByAggregate?.count)

  const username = useSelector((state) => state.username)

  const [likeItemMutation] = useMutation(LIKE_ITEM)
  const [dislikeItemMutation] = useMutation(DISLIKE_ITEM)

  const likeItem = useCallback(
    (e) => {
      //like or dislike video based on current like state
      if (!liked) {
        likeItemMutation({ variables: { id: data.id } }).then(() => {
          setLiked(!liked)
          setLikeCount((count) => count + 1)
        })
      } else {
        dislikeItemMutation({ variables: { id: data.id } }).then(() => {
          setLiked(!liked)
          setLikeCount((count) => count - 1)
        })
      }
      e.stopPropagation()
    },
    [data, dislikeItemMutation, likeItemMutation, liked]
  )

  useEffect(() => {
    // check if user liked video
    if (data) {
      if (data.likedBy.length > 0) {
        data.likedBy.some((i) => {
          if (currentUser && i.id === currentUser.id) {
            setLiked(true)
            setLikeCount(data.likedByAggregate.count)
            return true
          }
          setLiked(false)
          setLikeCount(data.likedByAggregate.count)
        })
      } else {
        setLiked(false)
        setLikeCount(data.likedByAggregate.count)
      }
    }
  }, [currentUser, data])

  return (
    <Item onClick={() => onClick(data)}>
      <ImageDetails>
        <ThumbnailWrapper>
          <Thumbnail src={data.thumbnail} />
          <ImageDetailsUserWrapper>
            <LikeWrapper>
              {liked ? <HeartIconSolidProp onClick={likeItem} /> : <HeartIconProp onClick={likeItem} />}
            </LikeWrapper>
            <UserAndCountWrapper>
              {data.user && (
                <ImageDetailsUser>
                  <UserImageWrapper>
                    <AvatarIcon small currentUser={data.user} />
                  </UserImageWrapper>
                  <UserName>{data.user.name}</UserName>
                </ImageDetailsUser>
              )}
              <ImageLikeCountAndButton>
                <LikeCount>
                  {likeCount} {likeCount === 1 ? 'Like' : 'Likes'}
                </LikeCount>
              </ImageLikeCountAndButton>
            </UserAndCountWrapper>
          </ImageDetailsUserWrapper>
        </ThumbnailWrapper>
        <SongName>
          {data.song.artist} – {data.song.title}
        </SongName>
        <TypeWrapper>
          {data.song.performanceType && (
            <ProppedType type={data.song.performanceType}>
              {data.song.performanceType === 'HipHopFreestyle' ? 'HipHop' : data.song.performanceType}
            </ProppedType>
          )}
          {data.song.likedBy}
        </TypeWrapper>
      </ImageDetails>
    </Item>
  )
}

GalleryItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
}

const LikeWrapper = styled.div(tw`
  flex
  justify-end
  mt-4
`)

const UserAndCountWrapper = styled.div(tw`
 flex
 justify-between
 w-full
`)

const HeartIconProp = styled(HeartIcon)(tw`
  w-10
  h-10
  p-1
  text-black
  cursor-pointer
  bg-white
`)
const HeartIconSolidProp = styled(HeartIconSolid)(tw`
  w-10
  h-10
  p-1
  text-red-500
  cursor-pointer
  bg-white

`)

const ImageDetails = styled.div(tw`
  w-full
  h-full
  mt-2
`)

const ImageDetailsUserWrapper = styled.div(tw`
  flex
  flex-col
  justify-between
  items-end
`)
const ImageDetailsUser = styled.div(tw`
  flex
  items-center
  p-4
`)

const UserImageWrapper = styled.div(tw`
  w-6
  h-6
  rounded-full
  overflow-hidden
`)

const UserImage = styled.img(tw`
  w-full
`)

const UserName = styled.p(tw`
  font-bold
  text-white
  text-xs
  ml-2
`)

const ImageLikeCountAndButton = styled.div(tw`
  flex
  items-center
  p-4
`)

const LikeCount = styled.p(tw`
  text-white
  text-sm
`)

const SongName = styled.p(tw`
  px-4
  text-sm
  mt-4
  text-gray-700
  hover:cursor-pointer
`)
const TypeWrapper = styled.div(tw`
  px-4
  mt-2
`)

const typeStyling = ({ type }) => {
  if (type === 'Dance') {
    return tw`
      bg-blue-300
    `
  } else if (type === 'Karaoke') {
    return tw`bg-yellow-200`
  } else if (type === 'HipHopFreestyle') {
    return tw`bg-green-200`
  }
}

const Type = styled.div(tw`

  w-auto
  p-1
  float-left
  text-xs
  leading-none
`)

const ProppedType = styled(Type)`
  ${typeStyling}
`

const Thumbnail = styled.img(tw`
  w-full
  bg-gray-400
`)

const ThumbnailWrapper = styled.div(tw`
  aspect-w-16
  aspect-h-9
  overflow-hidden
  `)

const Item = styled.div(tw`
  w-gridItem
  h-auto
  flex-overflow
  hover:cursor-pointer
`)
