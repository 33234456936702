import Footer from 'components/Footer'
import TopNav from 'components/TopNav'
import PropTypes from 'prop-types'
import React from 'react'

export default function Layout({ children }) {
  return (
    <div>
      <TopNav />
      {children}
      <Footer />
    </div>
  )
}
Layout.propTypes = { children: PropTypes.any }
