import { useApolloClient, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import tw from 'twin.macro'

const QUERY_SONGS = gql`
  query QuerySong {
    queryKaraokeSong {
      id
      title
      artist
    }
  }
`
export const GALLERY_FILTER_QUERY_SONG = gql`
  query galleryQueryBySong($songid: ID!) {
    queryKaraokePerformance(filter: { isUploaded: true }) @cascade(fields: ["song"]) {
      id
      offset
      song(filter: { id: [$songid] }) {
        title
        artist
        performanceType
        videoUrl
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        id
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`
export const GALLERY_FILTER_QUERY_TYPE = gql`
  query galleryQueryByType($type: PerformanceType!) {
    queryKaraokePerformance(filter: { isUploaded: true }) @cascade(fields: ["song"]) {
      id
      offset
      song(filter: { performanceType: { eq: $type } }) {
        title
        artist
        performanceType
        videoUrl
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        id
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`

export const GALLERY_FILTER_QUERY = gql`
  query galleryQueryByInit {
    queryKaraokePerformance(filter: { isUploaded: true }) @cascade(fields: ["song"]) {
      id
      offset
      song {
        title
        artist
        performanceType
        videoUrl
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        id
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`
export const GALLERY_FILTER_QUERY_LIKED = gql`
  query galleryQueryByInit {
    queryKaraokePerformance(filter: { isUploaded: true }) @cascade(fields: ["song"]) {
      id
      offset
      song {
        title
        artist
        performanceType
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        id
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`
export const GALLERY_FILTER_QUERY_RECENT = gql`
  query galleryQueryByInit {
    queryKaraokePerformance(filter: { isUploaded: true }) @cascade(fields: ["song"]) {
      id
      offset
      song {
        title
        artist
        performanceType
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        id
        name
      }
      user {
        id
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`
export default function GalleryFilter({ onChange, onData, selectedSong, first, offset }) {
  const [currentTab, setCurrentTab] = useState('all')
  const { loading, data } = useQuery(QUERY_SONGS, {
    onCompleted: (data) => formatData(data),
  })
  const [currentFilter, setCurrentFilter] = useState('recent')
  const selection = useRef()
  const client = useApolloClient()
  const [currentData, setCurrentData] = useState(null)
  const [songList, setSongList] = useState([])
  const [currentAction, setCurrentAction] = useState('tab')
  const [currentSongID, setCurrentSongID] = useState(null)

  const formatData = (e) => {
    let arr = []
    console.log(e)
    if (e.queryKaraokeSong.length > 0) {
      e.queryKaraokeSong.forEach((i) => {
        let res = {}
        res.value = i.id
        res.label = i.artist + ' – ' + i.title
        arr.push(res)
      })
      setSongList(arr)
    }
  }

  const handleTabChange = useCallback(
    (e) => {
      setCurrentTab(e.target.value)
      setCurrentAction('tab')
      // onTabChange(e.target.value)
      // type({ variables: { type: e.target.value } })
      typeQuery(e.target.value, first, offset)

      if (selectedSong !== '-1' && selection.current) {
        selection.current.select.clearValue()
        setCurrentSongID(null)
        onChange('-1')
      }
    },
    [first, offset, onChange, selectedSong, typeQuery]
  )
  const handleFilterChange = useCallback(
    (e) => {
      setCurrentFilter(e.target.value)
      filterData(currentData, e.target.value)
    },
    [currentData, filterData]
  )

  const handleSelectorChange = useCallback(
    (e, { action }) => {
      if (e !== null) {
        onChange(e.value)
        setCurrentAction('selection')
        setCurrentSongID(e.value)
        // song({ variables: { songid: e.value } })

        songQuery(e.value, first, offset)

        if (currentTab !== 'all') {
          setCurrentTab('all')
          // onTabChange('all')
        }
      }
    },
    [currentTab, first, offset, onChange, songQuery]
  )

  const songQuery = useCallback(
    (songid, first, offset) => {
      client
        .query({
          query: GALLERY_FILTER_QUERY_SONG,
          variables: { songid, first, offset },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          filterData(data, currentFilter)
        })
    },
    [client, currentFilter, filterData]
  )

  const typeQuery = useCallback(
    (type, first, offset) => {
      if (type !== 'all') {
        client
          .query({
            query: GALLERY_FILTER_QUERY_TYPE,
            fetchPolicy: 'network-only',
            variables: { type: type, first, offset },
          })
          .then(({ data }) => {
            filterData(data, currentFilter)
          })
      } else {
        client
          .query({
            query: GALLERY_FILTER_QUERY,
            fetchPolicy: 'network-only',
            variables: { first, offset },
          })
          .then(({ data }) => {
            filterData(data, currentFilter)
            // setCurrentData(filtered)
          })
      }
    },
    [client, currentFilter, filterData]
  )

  const filterData = useCallback((data, filter) => {
    if (data && filter) {
      if (filter === 'recent') {
        let result = {}
        let sortableData = data.queryKaraokePerformance
        // .filter((i) => i.createdAt !== null)
        result.queryKaraokePerformance = sortableData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setCurrentData(result)
        return result
      } else if (filter === 'liked') {
        let result = {}
        let sortableData = data.queryKaraokePerformance
        result.queryKaraokePerformance = sortableData
          .slice()
          .sort((a, b) => b.likedByAggregate.count - a.likedByAggregate.count)
        setCurrentData(result)
        return result
      }
    } else {
      return console.error('Not enough Parameter')
    }
  }, [])

  useEffect(() => {
    client
      .query({
        query: GALLERY_FILTER_QUERY,
        fetchPolicy: 'network-only',
        variables: { first, offset },
      })
      .then(({ data }) => filterData(data, currentFilter))
  }, [])

  // useEffect(() => {
  //   if (currentAction === 'selection') {
  //     songQuery(currentSongID, first, offset)
  //   } else if (currentAction === 'tab') {
  //     typeQuery(currentTab, first, offset)
  //   }
  // }, [offset])

  useEffect(() => {
    onData(currentData)
  }, [currentData])

  return (
    <div tw="flex w-full">
      <div tw="flex w-full justify-between">
        <div onInput={handleTabChange} name={'tab'}>
          <ProppedTabLabel active={currentTab === 'all'}>
            <SectionTitle type="radio" name="tab" value="all" defaultChecked={currentTab === 'all'} />
            All
          </ProppedTabLabel>
          <ProppedTabLabel active={currentTab === 'Karaoke'}>
            <SectionTitle type="radio" name="tab" value="Karaoke" defaultChecked={currentTab === 'Karaoke'} />
            Karaoke
          </ProppedTabLabel>
          <ProppedTabLabel active={currentTab === 'Dance'}>
            <SectionTitle type="radio" name="tab" value="Dance" defaultChecked={currentTab === 'Dance'} />
            Dance
          </ProppedTabLabel>
        </div>
        <div>
          <div onInput={handleFilterChange}>
            <ProppedTabLabel active={currentFilter === 'recent'}>
              <SectionTitle type="radio" name="filter" value="recent" defaultChecked={currentFilter === 'recent'} />
              Most recent
            </ProppedTabLabel>
            <ProppedTabLabel active={currentFilter === 'liked'}>
              <SectionTitle type="radio" name="filter" value="liked" defaultChecked={currentFilter === 'liked'} />
              Most liked
            </ProppedTabLabel>
          </div>
          <Selector>
            {loading ? (
              <p>Loading ... </p>
            ) : (
              data &&
              songList.length > 0 && (
                <Select
                  defaultValue={'-1'}
                  isClearable
                  ref={selection}
                  onChange={handleSelectorChange}
                  options={songList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#efeff0',
                      primary: 'black',
                    },
                  })}
                  styles={{
                    valueContainer: () => ({
                      padding: 12,
                    }),
                  }}
                />
              )
            )}
          </Selector>
        </div>
      </div>
    </div>
  )
}

{
  /* <Selector>
            <Selection ref={selection} onInput={handleSelectorChange}>
              <option value="-1" key={'-1'} defaultChecked={() => selectedSong === '-1'}>
                Please choose
              </option>
              {data.queryKaraokeSong.length > 0 &&
                data.queryKaraokeSong.map((i) => {
                  return (
                    <option value={i.id} key={i.id} defaultChecked={() => selectedSong === i.id}>
                      {i.artist} - {i.title}
                    </option>
                  )
                })}
            </Selection>
            <DropdownHandle>
              <svg xmlns="http://www.w3.org/2000/svg" tw={'h-4 w-4'} fill="none" viewBox="0 0 24 24" stroke="black">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </DropdownHandle>
          </Selector> */
}

GalleryFilter.propTypes = {
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onData: PropTypes.func,
  selectedSong: PropTypes.string,
}

const activeLabel = ({ active }) => {
  if (active) {
    return tw`
        border-b-4
        border-zalando-orange
      `
  }
}

const TabLabel = styled.label(tw`
    text-lg
    font-bold
    mx-4
    pb-1
    tracking-widest
    cursor-pointer
    `)

const ProppedTabLabel = styled(TabLabel)`
  ${activeLabel}
`

const SectionTitle = styled.input(tw`
  text-base
  font-bold
  tracking-widest
  appearance-none
`)

const Selector = styled.div(tw`
  relative 
  mt-4
  w-auto
`)
