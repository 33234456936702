import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import React from 'react'

export default function HeartIconWrapper({ liked, onClick }) {
  return (
    <div>
      <HeartIconSolid
        style={{
          width: 24,
          height: 24,
          color: liked ? '#EF4444' : 'white',
          cursor: 'pointer',
        }}
        onClick={() => onClick()}
      />
    </div>
  )
}

HeartIconWrapper.propTypes = {
  liked: PropTypes.bool,
  onClick: PropTypes.func,
}
