import 'react-multi-carousel/lib/styles.css'

import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import GalleryItem from 'components/GalleryItem'
import Lightbox from 'components/Lightbox'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { isBrowser, isMobile, isTablet } from 'react-device-detect'
import Carousel from 'react-multi-carousel'
import ReactPaginate from 'react-paginate'
import tw from 'twin.macro'

import Pagination from './Pagination'

export default function GalleryGrid({
  imageList,
  pagination,
  numberOfImages,
  filtered,
  selectedSong,
  selectedCat,
  onNext,
  gridOptions,
}) {
  const [images, setImages] = useState([])
  //   const [thumbs, setThumbs] = useState([])

  const [stateData, setStateData] = useState([])
  const [first, setFirst] = useState(4)
  const [offset, setOffset] = useState(0)
  let perPage = numberOfImages
  const [pageCount, setPageCount] = useState(null)
  const [deviceType, setDeviceType] = useState('mobile')

  const [currentImage, setCurrentImage] = useState(null)
  const [toggler, setToggler] = useState(false)

  const receivedData = useCallback(
    async (data) => {
      setStateData(data)
    },
    [setStateData]
  )

  const openLightboxOnSlide = useCallback((data) => {
    setCurrentImage(data)
    setToggler(true)
  }, [])

  const transformData = useCallback(async (data) => {
    let urls = []
    if (data) {
      for (let i of data) {
        urls.push(i.mp4)
      }
      setImages(urls)
      return
    }
  }, [])

  useEffect(() => {
    let mounted = true
    if (imageList) {
      transformData(imageList.queryKaraokePerformance) // transform URLs for FS Lightbox
      receivedData(imageList.queryKaraokePerformance)
    }
    return () => (mounted = false)
  }, [filtered, imageList, receivedData, transformData])

  useEffect(() => {
    if (isMobile) {
      setDeviceType('mobile')
    } else if (isTablet) {
      setDeviceType('tablet')
    } else if (isBrowser) {
      if (window && window.innerWidth >= 1600) {
        setDeviceType('superLargeDesktop')
      } else {
        setDeviceType('desktop')
      }
    }
  }, [])

  return (
    <>
      {filtered ? (
        !imageList ? (
          <span>Loading...</span>
        ) : stateData.length === 0 ? (
          <EmptyState>Currently no Videos to see</EmptyState>
        ) : (
          <Grid>
            {stateData.map((data, i) => (
              <GalleryItem onClick={openLightboxOnSlide} data={data} key={data.id} />
            ))}
          </Grid>
        )
      ) : !imageList ? (
        <span>Loading...</span>
      ) : stateData.length === 0 ? (
        <EmptyState>Currently no Videos to see</EmptyState>
      ) : (
        /* <GridOverflow> */
        <Carousel responsive={gridOptions} ssr={true} deviceType={deviceType} itemClass={css({ paddingRight: 10 })}>
          {stateData.map((data, i) => (
            <GalleryItem onClick={openLightboxOnSlide} data={data} key={data.id} />
          ))}
        </Carousel>
        /* </GridOverflow> */
      )}

      {!imageList ? (
        <div></div>
      ) : (
        <Lightbox isOpen={toggler} onClose={() => setToggler(false)} data={currentImage} key={String(toggler)} />
      )}
    </>
  )
}

GalleryGrid.propTypes = {
  imageList: PropTypes.object,
  pagination: PropTypes.bool,
  numberOfImages: PropTypes.number,
}

const GridOverflow = styled.div(tw`
    flex
    w-full
    gap-8
    py-4
    overflow-scroll
  `)

const Grid = styled.div(tw`
    grid
    grid-cols-gallery
    w-full
    gap-8
    py-4
  `)

const EmptyState = styled.div(tw`
    w-full
    h-64
    flex
    justify-center
    items-center
    font-bold
    text-gray-400
  `)
