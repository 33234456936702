import styled from '@emotion/styled'
import { XIcon } from '@heroicons/react/solid'
import useKeypress from 'hooks/useKeypress'
import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'

import LightboxItem from './LightboxItem'

export default function Lightbox({ isOpen, onClose, data }) {
  useKeypress('Escape', () => {
    closeBox()
  })

  const closeBox = () => {
    onClose()
  }
  return (
    <ProppedLightbox isOpen={isOpen}>
      <Nav>
        <CloseBtn onClick={() => onClose()}>Close</CloseBtn>
      </Nav>
      <MediaWrapper>
        {data && (
          <LightboxItem
            isOpen={isOpen}
            videoSrcMp4={data.mp4}
            videoSrcWebM={data.webm}
            audioSrc={data.song.videoUrl}
            duration={data.song.duration}
            data={data}
            closeBox={closeBox}
          />
        )}
      </MediaWrapper>
    </ProppedLightbox>
  )
}

Lightbox.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
}

let openBox = ({ isOpen }) => {
  return isOpen ? tw`block` : tw`hidden`
}

const LightboxWrapper = styled.div(
  tw`
    fixed
    top-0
    left-0
    z-50
    w-screen
    h-screen
    bg-black
    bg-opacity-70
`
)

const ProppedLightbox = styled(LightboxWrapper)`
  ${openBox}
`

const Nav = styled.div(tw`
    w-full
    h-32
    fixed
`)

const CloseBtn = styled(XIcon)(tw`
    float-right
    text-white
    w-12
    m-4
    cursor-pointer
    hover:text-gray-500
`)

const MediaWrapper = styled.div(tw`
    flex
    flex-col
    justify-center
    items-center
    w-full
    h-full
`)
