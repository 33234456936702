import { useCallback, useEffect, useRef } from 'react'

// Custom react hook
const useOutsideClick = (onClick) => {
  const ref = useRef(null)

  const handleClick = useCallback(
    (e) => {
      if (ref.current === null) return
      const inside = ref.current.contains(e.target)
      if (inside) return

      onClick()
    },
    [onClick, ref]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [handleClick])

  return ref
}

export default useOutsideClick
